export const MAP_STATUS = {
  sealed: {
    pt: 'Lacrado',
    en: 'Sealed',
  },
  open: {
    pt: 'Disponível',
    en: 'Available',
  },
  closed: {
    pt: 'Indisponível',
    en: 'Unavailable',
  },
}

export const MAP_USER_STATUS = {
  draft: {
    pt: 'Aberta',
    en: 'draft',
  },
  submitted: {
    pt: 'Submetida',
    en: 'Submitted',
  },
  canceled: {
    pt: 'Cancelada',
    en: 'Canceled',
  },
  outdated: {
    pt: 'Arquivada',
    en: 'Archived',
  },
  approved: {
    pt: 'Aprovada',
    en: 'Approved',
  },
  declined: {
    pt: 'Recusada',
    en: 'Declined',
  },
  pre_approved: {
    pt: 'Parecer emitido',
    en: 'Parecer emitido',
  },
}

export const MAP_STATUS_COLORS = {
  sealed: 'medium',
  open: 'light-primary',
  closed: 'orange',
}

export const MAP_USER_STATUS_COLORS = {
  draft: 'light-orange',
  submitted: 'light-primary',
  canceled: 'medium',
  outdated: 'light',
  approved: 'primary',
  declined: 'danger',
  pre_approved: 'light-primary',
}

export const MAP_USER_VERSION_REASONS = {
  vacations: {
    pt: 'Dias de férias',
    en: 'Vacations',
  },
  credit_days: {
    pt: 'Dias de crédito',
    en: 'Credit days',
  },
  accumulate_days: {
    pt: 'Dias a cumular',
    en: 'Cumulate days',
  },
  sap_sync: {
    pt: 'Atualização',
    en: 'Update',
  },
  starter_map: {
    pt: 'Versão inicial',
    en: 'Initial map',
  },
}

export const TO_CHOOSE_MAP_USER_VERSION_REASONS = {
  vacations: {
    pt: 'Dias de férias',
    en: 'Vacations',
  },
  credit_days: {
    pt: 'Dias de crédito',
    en: 'Credit days',
  },
  accumulate_days: {
    pt: 'Cumular dias',
    en: 'Cumulate days',
  },
}

export const MAP_COMMENTS_TYPES = ['comment', 'approve', 'parecer', 'creditdays/parecer', 'creditdays/approve']

export const FULL_DAY_TYPES = ['allday', 'creditday']

export const PARTIAL_DAY_TYPES = [
  'morning',
  'afternoon',
  'halfday',
  'creditday-morning',
  'creditday-afternoon',
  'creditday-halfday',
]
export const VACATIONS_DAY_TYPES = ['allday', 'morning', 'afternoon', 'halfday']
export const CREDIT_DAY_TYPES = ['creditday', 'creditday-morning', 'creditday-afternoon', 'creditday-halfday']

export const countDays = days => {
  let count = 0
  if (!days.length) return 0

  for (const current of days) {
    if (PARTIAL_DAY_TYPES.includes(current.type)) {
      count += 0.5
    } else {
      count += 1
    }
  }

  return count
}
